function DocumentModel(options) {
    let model = {
        id: options.id,
        docnum: options.docnum || '',
        docinfo: options.docinfo || '',
        type: options.type || '',
        status: options.status,
        date_start: '',
        date_end: '',
        city_name: options.city_name || '',
        city_id: options.city_id || -1,
        sums: options.sums || [],
        sum_deposit: options.sum_deposit,
        sum_deposit_rest: options.sum_deposit_rest,
        sum_transfer: options.sum_transfer,
        sum_tarif: options.sum_tarif,
        sum_dostavka_out: options.sum_dostavka_out,
        sum_dostavka_in: options.sum_dostavka_in,
        sum_other_in: options.sum_other_in,
        sum_wash: options.sum_wash,
        dirty: options.dirty || false,
        sum_destruct: options.sum_destruct,
        sum: options.sum || 0,
        sum_doc: options.sum_doc || 0,
        sum_opl: options.sum_opl || 0,
        odo_out: options.odo_out || 0,
        odo_in: options.odo_in || 0, 
        gsm_out: options.gsm_out || 0,
        gsm_in: options.gsm_in || 0,
        datetime_out: '2023-03-23',
        datetime_in: '2023-03-23',
        date_out: options.date_out,
        date_in: options.date_in,
        time_out: options.time_out,
        time_in: options.time_in,
        comment_out: options.comment_out,
        comment_in: options.comment_in,
        client_name: options.client_name,
        client_phone: options.client_phone,
        territory: options.territory,
        is_destruct: options.is_destruct,
        destruct_info: options.destruct_info,
        needrepair: false,
        rel_doc: options.rel_doc || '',
        docs: options.docs || []
    };
    return model;
}

export default DocumentModel;